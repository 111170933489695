export type I18nMessages = {
  [locale: string]: {
    message: {
      [key: string]: string;
    };
  };
};

export const SEND_TRANSLATION = {
  URL: import.meta.env.VITE_SPEND_TRANSLATION_URL,
};

export async function fetchTranslationMessages(): Promise<I18nMessages> {
  const fetchMessages = await fetch(SEND_TRANSLATION.URL, {
    cache: "no-store",
  });
  return fetchMessages.json();
}
