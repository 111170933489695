<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <div class="flex flex-col items-center gap-4">
    <p class="font-heading text-xl font-extrabold">
      {{ $t("message.balance_ready_soon") }}
    </p>
    <p class="max-w-[575px] text-center text-base">
      {{ $t("error.taking_longer_than_expected") }}
      <br />
      {{ $t("error.payouts_can_take_a_few_minutes") }}
    </p>
  </div>
</template>
