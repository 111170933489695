<script setup lang="ts">
import { onMounted, computed, ref, inject, Ref } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { initMixpanel, trackEvent } from "@two-ui/mixpanel";

import {
  CustomerData,
  ECodeData,
  ProductData,
  RedemptionData,
  RedemptionResponse,
} from "../../api.generated/scion";
import {
  Collapsible,
  FAQs,
  HowTo,
  SavooBanner,
  SpendDetails,
  Support,
  ErrorMessage,
  Footer,
  LoadingView as SpendLoadingView,
} from "../../components";
import { TIMEOUT_ERROR_CODE } from "../../constant";
import { getSpendDetails, sendEventTracking } from "../../services/redemption";
import { LoadingView } from "@workspace/packages/payout/src/components";
import Markdown from "markdown-it";
import "@wegift/company-brand-assets/dist/design-system/css/font-f37Lineca.css";
import { fetchTranslationMessages, I18nMessages } from "../../i18n-messages";
import { OrderItemCustomisation } from "../../api.generated/scion/models/OrderItemCustomisation";
import TwoSelectField from "@two-components/components/TwoSelectField.vue";

const ENABLE_SAVOO_EXPERIMENT = import.meta.env.VITE_ENABLE_SAVOO_EXPERIMENT;

const SAVOO_EXPERIMENT_ENABLED_PRODUCTS =
  import.meta.env.VITE_SAVOO_EXPERIMENT_ENABLED_PRODUCTS?.split(",").map(
    (product: string) => product.trim()
  );

const props = defineProps<{
  token: string;
  // The customisation parameter is coming from the Wallet app
  customisation: OrderItemCustomisation | null;
  isAsset?: boolean;
}>();

const route = useRoute();
const { locale, setLocaleMessage } = useI18n();
const redemptionDetails = ref<RedemptionResponse>();
const isLoading = ref<boolean>(true);
const hasFailedLoadingRedemptionDetails = ref<boolean>(false);
const isExternalURL = ref<boolean>(true);
const translationsLoaded = ref<boolean>(false);
const blurryCodesLoading = ref<boolean>(false);
const isLongerThan30s = ref<boolean>(false);
const canadianLocaleOptions = [
  { id: "0", value: "en", label: "English (CA)" },
  { id: "1", value: "fr", label: "Français (CA)" },
];

const internalUrlParameters = ["exp", "locale"];

function deleteIternalUrlParam(params: URLSearchParams): URLSearchParams {
  internalUrlParameters.forEach((internalParameterName) =>
    params.delete(internalParameterName)
  );
  return params;
}

function createExternalRedemptionLink(externalUrl: string): string {
  const parsedUrl = new URL(externalUrl);
  const externalParameters = deleteIternalUrlParam(
    new URLSearchParams(window.location.search)
  );
  externalParameters.forEach((value, key, _) =>
    parsedUrl.searchParams.append(key, value)
  );

  return parsedUrl.href;
}

const handleShowCodes = async () => {
  blurryCodesLoading.value = true;
  try {
    const data = await getSpendDetails(props.token, exp.value, true);

    const externalUrl = data?.redemptionInformation?.externalRedemptionUrl;
    if (!!externalUrl) {
      window.location.href = createExternalRedemptionLink(externalUrl);
    } else {
      redemptionDetails.value = data;
    }

    hasFailedLoadingRedemptionDetails.value =
      redemptionDetails?.value?.status !== "SUCCESS";
  } catch (e) {
    hasFailedLoadingRedemptionDetails.value = true;
    console.error(e);
  } finally {
    blurryCodesLoading.value = false;
  }
};

const updateLongerThan30s = () => {
  isLongerThan30s.value = true;
  document.documentElement.style.setProperty("--bg-color-spend", "#F3F3F3");
};

const redemptionInformation = computed(() => {
  if (
    redemptionDetails.value &&
    redemptionDetails.value.redemptionInformation
  ) {
    if (props.customisation) {
      redemptionDetails.value.redemptionInformation.customisation =
        props.customisation;
    }
    const newBackgroundColor =
      redemptionDetails.value?.redemptionInformation?.customisation
        ?.backgroundColour;
    if (newBackgroundColor) {
      document.documentElement.style.setProperty(
        "--bg-color-spend",
        newBackgroundColor
      );
    }
  }
  return redemptionDetails.value?.redemptionInformation as RedemptionData;
});
const eCode = computed(
  () => redemptionDetails.value?.redemptionInformation?.eCode as ECodeData
);
const customer = computed(
  () => redemptionDetails.value?.redemptionInformation?.customer as CustomerData
);
const product = computed(
  () => redemptionDetails.value?.redemptionInformation?.product as ProductData
);
const support = computed(
  () => redemptionDetails.value?.redemptionInformation?.support
);
const hasSupportInfo = computed(
  () => support.value && (support.value.phone || support.value.url)
);
const exp = computed(() => (route?.query?.exp as string) || null);
const savooEnabledAndLinkHasBanner = computed(() => {
  return (
    ENABLE_SAVOO_EXPERIMENT === "true" &&
    SAVOO_EXPERIMENT_ENABLED_PRODUCTS.includes(product.value.code)
  );
});
const productIsCanadian = computed(() => {
  return product.value?.countryCode === "CA";
});

function setLocale(languageCode: string = "en") {
  locale.value = (route?.query?.locale as string) || languageCode;
}

const displayBlurryCodes = computed(() => {
  return !eCode?.value;
});

const HIDE_RUNA_MESSAGE_CUSTOMER_IDS = import.meta.env
  .VITE_HIDE_RUNA_MESSAGE_CUSTOMER_IDS
  ? JSON.parse(import.meta.env.VITE_HIDE_RUNA_MESSAGE_CUSTOMER_IDS)
  : [];

const shouldHideRunaMessage = computed(() =>
  HIDE_RUNA_MESSAGE_CUSTOMER_IDS.includes(customer.value?.hashedId)
);

onMounted(async () => {
  try {
    const translationsResult = await fetchTranslationMessages();
    const messages = translationsResult || ({} as I18nMessages);
    translationsLoaded.value = true;

    Object.keys(messages).forEach((locale) => {
      setLocaleMessage(locale, messages[locale]);
    });
    setLocale();
  } catch (e) {
    hasFailedLoadingRedemptionDetails.value = true;
    isLoading.value = false;
    console.error(e);
    return;
  }

  try {
    redemptionDetails.value = await getSpendDetails(
      props.token,
      exp.value,
      false
    );
    const productLanguageCode =
      redemptionDetails.value?.redemptionInformation?.product?.languageCode;
    setLocale(productLanguageCode);

    hasFailedLoadingRedemptionDetails.value =
      redemptionDetails?.value?.status !== "SUCCESS";

    const externalUrl =
      redemptionDetails.value?.redemptionInformation?.externalRedemptionUrl;

    if (!!externalUrl) {
      isExternalURL.value = true;
      window.location.href = createExternalRedemptionLink(externalUrl);
      return;
    } else {
      isExternalURL.value = false;
      trackEvent(
        'Network: User navigates to the "Payout Link" page.',
        {
          customerId: customer.value?.hashedId,
          savooAciveAndLinkHasBanner: savooEnabledAndLinkHasBanner.value,
          blurryCodesEnabled: displayBlurryCodes.value,
        },
        props.token
      );
    }
  } catch (e) {
    hasFailedLoadingRedemptionDetails.value = true;
    console.error(e);
  }

  isLoading.value = false;
});

const onTnCMounted = () => {
  if (
    redemptionDetails.value &&
    redemptionDetails.value.redemptionInformation?.product?.terms
  ) {
    const md = new Markdown();
    const howTo = md.render(
      redemptionDetails.value.redemptionInformation?.product?.terms
    );
    const contentContainer = document.getElementById("tncs");

    if (contentContainer) {
      contentContainer.innerHTML = howTo;
    }
  }
};

initMixpanel();
</script>

<template>
  <LoadingView v-if="isLoading" :translationsLoaded="translationsLoaded" />

  <div v-else-if="hasFailedLoadingRedemptionDetails || isLongerThan30s">
    <ErrorMessage
      :error-code="
        isLongerThan30s ? TIMEOUT_ERROR_CODE : redemptionDetails?.errorCode
      "
      :is-asset="props.isAsset"
    />
  </div>

  <div v-else-if="!isExternalURL" class="flex w-full justify-center">
    <div class="max-w-[528px]">
      <section v-if="productIsCanadian" class="flex justify-end">
        <TwoSelectField
          v-model="locale"
          :options="canadianLocaleOptions"
          custom-select-class="bg-white"
          class="mb-2 w-1/4"
        />
      </section>

      <section v-if="savooEnabledAndLinkHasBanner" title="Savoo banner">
        <SavooBanner
          class="mb-6"
          :product-code="product.code"
          :customer-id="customer?.hashedId"
          :token="token"
        />
      </section>
      <section
        title="Redemption information"
        class="font-f37lineca mb-4 rounded-2xl bg-white p-4"
      >
        <div
          v-if="blurryCodesLoading"
          class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75"
        >
          <div
            class="flex w-96 flex-col items-center justify-center rounded-lg bg-white p-6 shadow-lg"
          >
            <SpendLoadingView
              :translations-loaded="translationsLoaded"
              @longer-than-30s="updateLongerThan30s"
            />
          </div>
        </div>
        <SpendDetails
          :redemption-data="redemptionInformation"
          :token="token"
          :display-blurry-codes="displayBlurryCodes"
          :locale="locale"
          @blurry-codes-toggle="handleShowCodes"
        >
        </SpendDetails>
      </section>

      <section
        v-if="product?.redeemInstructions"
        id="how-to"
        :title="$t('message.how_to_use_your_gift_card')"
        class="font-f37lineca mb-4 rounded-2xl bg-white p-4"
      >
        <HowTo :redeem-instructions="product.redeemInstructions"> </HowTo>
      </section>

      <section
        :title="$t('message.faq_and_support')"
        :class="[
          'font-f37lineca mb-4 rounded-2xl bg-white',
          !product?.faqs?.length && !hasSupportInfo && 'hidden',
        ]"
      >
        <FAQs
          v-if="product?.faqs?.length"
          class="border-b border-gray-200 last:border-b-0"
          :faqs="product?.faqs"
          :primary-colour="
            redemptionInformation.customisation?.primaryColour || 'black'
          "
        >
        </FAQs>

        <Support
          v-if="support && hasSupportInfo"
          :product="product"
          :support="support"
          :primary-colour="
            redemptionInformation.customisation?.primaryColour || 'black'
          "
        ></Support>
      </section>

      <section
        v-if="product?.terms"
        :title="$t('message.terms_and_conditions')"
        class="font-f37lineca mb-4 rounded-2xl bg-white p-4"
      >
        <Collapsible
          :title="$t('message.terms_and_conditions')"
          @vue:mounted="onTnCMounted"
        >
          <div id="tncs"></div>
        </Collapsible>
      </section>

      <Footer
        v-if="!displayBlurryCodes"
        :e-code-data="eCode"
        :sender-name="
          redemptionInformation?.customisation?.senderName || customer?.name
        "
        :product="product"
        :hideRunaMessage="shouldHideRunaMessage"
      />
    </div>
  </div>
</template>

<style scoped></style>
