<script setup lang="ts">
import { computed } from "vue";
import { ErrorIcon } from "../";
import { useI18n } from "vue-i18n";
import { TIMEOUT_ERROR_CODE } from "../../constant";
const { t } = useI18n();

const props = defineProps<{
  errorCode: string | undefined;
  isAsset?: boolean;
}>();

const errorMessage = computed(() => {
  switch (props.errorCode) {
    case "RE001":
      return t("error.unknown_token");
    case "RE002":
      return t("error.inactive_customer");
    case "RE003":
      return t("error.invalid_token");
    case "RE004":
      return t("error.expired_token");
    case "RE005":
      return t("error.cancelled_token");
    case "RE006":
      return t("error.out_of_stock_product");
    case "RE007":
      return t("error.inactice_product");
    case "RE008":
      return t("error.processing");
    case "RE009":
      return t("error.too_soon");
    case "RE010":
      return t("error.out_of_funds");
    case "RE011":
      return t("error.unknown_token");
    case "RE012":
      return t("error.invalid_order_item");
    case TIMEOUT_ERROR_CODE:
      return "";
    case "RE999":
    default:
      return t("error.general");
  }
});
</script>

<template>
  <div
    v-if="errorCode === TIMEOUT_ERROR_CODE"
    class="mt-12 flex flex-col items-center gap-4"
  >
    <div v-if="!isAsset" style="min-height: 86.5px">
      <!-- This is to push the SPL error message down as much as the MPL asset error message is pushed by the Back button -->
    </div>
    <p class="font-heading text-xl font-extrabold">
      {{ $t("message.balance_ready_soon") }}
    </p>
    <p class="max-w-[575px] text-center text-base">
      {{ $t("error.taking_longer_than_expected") }}
      <br />
      {{ $t("error.payouts_can_take_a_few_minutes") }}
    </p>
  </div>
  <div v-else class="h-full">
    <div class="m-auto max-w-xl p-4 md:p-10">
      <div class="">
        <div class="rounded-2xl bg-grey-300 px-3 py-4 md:p-6">
          <div class="mb-4 flex items-center">
            <div class="m-auto drop-shadow-xl">
              <ErrorIcon />
            </div>
          </div>

          <div class="mb-2 text-center text-lg font-semibold md:text-2xl">
            {{ $t("error.title") }}
          </div>

          <div class="text-center text-sm md:text-base">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
