export const PRODUCTION = "production";
export const SANDBOX = "sandbox";
export const ORG = "wegift";
export const PROJECT = "spend";
export const DEVELOPMENT = "development";
export const TIMER_INTERVAL_10_SEC = 10_000;
export const TIMER_INTERVAL_3_SEC = 3_000;
export const TIMER_INTERVAL_30_SEC = 30_000;
export const TIMER_INTERVAL_1_SEC = 1_000;
export const TIMEOUT_ERROR_CODE = "RE998";
