import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faInfoCircle,
  faChevronLeft,
  faCircleInfo,
  faCheck,
  faChevronsLeft,
  faBarsFilter,
  faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";

library.add(
  faInfoCircle,
  faCircleInfo,
  faChevronLeft,
  faCheck,
  faChevronsLeft,
  faBarsFilter,
  faTriangleExclamation
);
